// breakpoints

$S:     480px;
$SM:     640px;
$M:     768px;
$L:     1024px;
$maxL:    $L - 1px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == SM {
   @media only screen and (min-width: $SM) { @content; }
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; }
  }
  @else if $canvas == maxL {
   @media only screen and (max-width: $maxL) { @content; }
  }
}

// super light grid - it works with the .cd-container class inside style.scss

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

