@import "../bourbon/bourbon";
@import "../neat/neat";
@import '../partials/variables';
@import '../partials/layout';
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);

/* Primary style */

html {
	font-size: 62.5%;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
	font: {
		size: 1.6rem;
		family: $primary-font;
	}
	color: $text;
	background-color: $white;

	@include MQ(maxL) {
		&.nav-on-left.overflow-hidden {
			overflow: hidden;
		}
	}
}
a {
	color: $text;
}
a, a:hover, a:focus {
	text-decoration: none;
	outline: none;
}

img {
	/* make images responsive */
	max-width: 100%;
}

input {
	font-family: $primary-font;
	font-size: 1.6rem;
}

input[type="search"]::-ms-clear {
	/* removes close icon - IE */
    display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.container {
	@include outer-container();
	max-width: 1200px;
	position: relative;
	padding: 0 10px;
	@include MQ(L) {
		padding: 0;
	}
}
.outer-container {
	@include outer-container;
}

.icon {
	display: inline-block;
	position: relative;
	top: 3px;
	margin-right: 5px;
}
.icon16 {
	width: 16px;
	height: 16px;
}
.icon32 {
	width: 32px;
	height: 32px;
}
.icon-advice {
	background: url('../img/icon-advice.svg') no-repeat 0 0;
	background-size: contain;
}
.icon-event {
	background: url('../img/icon-event.svg') no-repeat 0 0;
	background-size: contain;
}
.icon-catalogue {
	background: url('../img/icon-catalogue.svg') no-repeat 0 0;
	background-size: contain;
}
.icon-newsletter {
	background: url('../img/icon-newsletter.svg') no-repeat 0 0;
	background-size: contain;
}

.button, .button:focus, .button:active, .button:visited, .button {
	text-align: center;
	text-transform: uppercase;
	color: $white;
	font-weight: 300;
	@include font-size(1.4);
	@include border-radius(20px);
	padding: 9px 20px;
	min-width: 100px;
	outline: 0;
	border: 0;
	display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  overflow: hidden;
}
.button:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $white;
  background: #2098d1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.button-blu {
	background: $blu;
}
.button-red {
	background: $red;
}

.button-blu:before {
	background: darken($blu, 8%);
	color: $white;
}
.button-red:before {
	background: darken($red, 8%);
	color: $white;
}

.button:hover:before, .button:focus:before, .button:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}



/* --------------------------------

support for no js

-------------------------------- */

.no-js .cd-primary-nav {
	position: relative;
	height: auto;
	width: 100%;
	overflow: visible;
	visibility: visible;
	z-index: $content;
}

.no-js .cd-search {
	position: relative;
	top: 0;
	opacity: 1;
	visibility: visible;
}

@include MQ(L) {
	.no-js .cd-primary-nav {
		position: absolute;
		z-index: $above-content;
		display: inline-block;
		width: auto;
		top: 0;
		right: 150px;
		padding: 0;
	}

	.no-js .nav-is-fixed .cd-primary-nav {
		position: fixed;
	}
}


/* --------------------------------

Homepage

-------------------------------- */


/* -- BRANDING SECTION -- */

.branding {
	height: calc(100vh - 140px);
	margin: 0;

	@media (min-height: 800px) and (min-width: 1024px) {
		height: calc(100vh - 220px);
	}
}
.branding-title {
	width: 100%;
	height: calc(100% - 280px);
	background: url('../img/branding-small.jpg') no-repeat center 0;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	  h1 {
	  	display: none;
	  }

	@include MQ(S) {
		background: url('../img/branding-mobile.jpg') no-repeat center center;
		-webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	  height: calc(100% - 180px);
	}
	@include MQ(M) {
		background: url('../img/branding-tablet.jpg') no-repeat center center;
		-webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	  height: calc(100% - 140px);
	}
	@include MQ(L) {
		background: url('../img/branding.jpg') no-repeat center 0;
		-webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	}

}

.inner-branding {
	height: 242px;
	margin: 0;
}
.inner-branding-title {
	width: 100%;
	height: 100%;
	background: url('../img/chi-siamo-bck.jpg') no-repeat center 0;
	-webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	  position: relative;
	  -moz-box-shadow:inset 0px -2px 11px #666;
-webkit-box-shadow:inset 0px -2px 11px #666;
box-shadow:inset 0px -2px 11px #666;
	  h1 {
	  	text-align: center;
	  	position: absolute;
	  	bottom: 35px;
	  	left: 0;
	  	width: 100%;
	  	font-family: $secondary-font;
	  	@include font-size(3.6);
	  	color: $red;
	  	@include MQ(M) {
	  		@include font-size(4.8);
	  	}
	  }
}

/* -- ANIMALS CATEGORY SELECTION -- */

.category-animals {
	height: 20px;
	padding: 20px 0;
	height: 140px;
	position: relative;
	a {
		figure {
			position: relative;
			overflow: hidden;
			margin: 0 auto;
			width: 120px;
			height: 120px;
		}
		figure img {
			position: relative;
			display: block;
			width: 100%;
			opacity: 0.7;
			transition: all 0.3s;
			transform: translateY(0);
		}
		svg {
			position: absolute;
			z-index: 10;
			width: 95%;
			top: 0;
			left: 0;
			height: 95%;
		}
		svg circle {
			fill: none;
		}
		figcaption {
			position: absolute;
			z-index: 11;
			width: 100%;
			bottom: 20px;
			height: auto;
			text-align: center;
		}
		figcaption .cat-name {
			transition: transform 0.3s;
			transform: translateY(0);
		}
	}
	@include MQ(M) {
		padding: 15px 0;
		height: 140px;
	}
	@include MQ(L) {
		a  {
			figcaption {
				position: absolute;
				z-index: 11;
				width: 100%;
				bottom: 15px;
				height: auto;
				text-align: center;
			}
		}
		a:hover {
			svg circle {
				stroke: $grey;
				stroke-width: 2px;
			}
			figure img{
				opacity: 1;
				transform: translateY(10px);
			}
			figcaption .cat-name {
				transform: translateY(-10px);
			}
		}
	}
}

 .category-animals-slider {
	height: 130px;
}
.category-animals-slider .swiper-slide {
	text-align: center;
	box-sizing: border-box !important;
}
.category-animals-slider .swiper-slide > a {
	display: block;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}
.category-animals-slider .swiper-slide img {
	display: block;
	width: 100%;
	height: auto;
}
.category-animals-slider .swiper-slide p {
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 10px;
}

.category-animals-slider .swiper-wrapper {
	overflow: hidden;
}
.category-animals-slider .swiper-slide {
	@include MQ(M) {
		width: calc(100% / 6);
		float: left;
		padding: 0 25px;
	}

}
.scroll {
	display: none;
	width: 50px;
	height: 50px;
	@include border-radius(50%);
	border: 2px solid $grey;
	cursor: pointer;
	text-align: center;
	color: $grey;
	padding-top: 15px;
	i {
		-webkit-animation-duration: 1s;
		-moz-ms-duration: 1s;
		-webkit-animation-duration: 1s;

  		-webkit-animation-iteration-count: 5;
  		-moz-animation-iteration-count: 5;
  		-ms-animation-iteration-count: 5;
	}
	@include MQ(M) {
		display: block;
		position: absolute;
		bottom: 150px;
		left: 50%;
		transform: translateX(-50%);
	}
}

/* -- SECTION & ARTICLES -- */

.article--content {
	padding: 25px 35px;
}
.figure-rounded {
	overflow: hidden;
	width: 115px;
	height: 115px;
	@include border-radius(50%);
	border: 1px solid $dark;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
.article {
	p {
		@include font-size(1.6);
		@include line-height(1.9);
		font-weight: 300;
	}
	h2 {
		font-weight: 400;
		@include font-size(2.4);
	}
	h3 {
		font-weight: 500;
	}

}
.article .tag {
	@include font-size(1.4);
	@include line-height(1.9);
	text-transform: uppercase;
	font-weight: 300;
}
.article--action {
	overflow: hidden;
	a {
		float: left;
	}
}
.article-bookmark {
	position: relative;
}

.bookmark {
	position: absolute;
	right: 10px;
	top: -5px;
	width: 45px;
	height: 70px;
	background: url('../img/ribbon.png') no-repeat 0 0;
	background-size: contain;
	z-index: 10;
}
.bookmark .icon {
	position: absolute;
	left: 48%;
	top: 10%;
	transform: translate(-50%, 0);
	margin: 0;
}
.article-bck {
	position: relative;
	transition: all 1s ease;

	a{
		padding: 30px;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		text-align: center;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		transition-delay: .5s;
		transition-delay: .5s;
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.45) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.45) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
		@include MQ(L) {
			background: none;
			filter: none;
		}

		h2 {
			font-family: 'pacifico', Georgia, serif;
			color: $white;
			@include font-size(2.4);
			@include line-height(2.8);
			position: absolute;
			top: 10%;
			left: 0;
			width: 100%;
			strong {
				@include font-size(3);
				@include line-height(3.2);
				color: #ffd75e;
			}
		}
		.button {
			position: absolute;
			bottom: 20%;
			left: 50%;
			transform: translate(-50%,0);
			transition: all 1s;
			@include MQ(L) {
				opacity: 0;
			}
		}
		.note {
			display: block;
			width: 100%;
			position: absolute;
			bottom: 15px;
			color: $white;
			left: 0;
			@include font-size(1.2);
			font-weight: 300;
			transition: all 1s;
			@include MQ(L) {
				opacity: 0;
			}
		}
	}
}

@include MQ(L){
	.article-bck a:hover {
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.45) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.45) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
		transition: all 1s ease;
		.button, .note {
			opacity: 1;
		}
	}
}

/* -- FEATURED ARTICLES SECTION -- */

section {
	margin: 15px 0;
}

.featured-articles .outer-container {
	position: relative;
}
.featured-articles .article-left {
	background-color: $grey;
	margin-bottom: 15px;
	h2 {
		color: $blu;
		@include font-size(2.8);
		margin: 15px 0;
	}
	p {
		margin: 10px 0;
	}
	.tag {
		color: $blu;
	}
	.article--action {
		margin-top: 10px;
	}
	a {
		margin: 0 5px 5px 0;
		text-transform: none;
		@include font-size(1.6);
	}
	.article--content {
		max-width: 480px;
		margin: 0 auto;
	}
}
.featured-articles .article-right {
	height: 25em;
}
.featured-articles .article-bck {
	background: url('../img/banner01.jpg') no-repeat center center;
	background-size: cover;
}


@include MQ(M) {
	.featured-articles .article-left {
		margin-bottom: 0;
	}
	section{
		.article-left {
			@include span-columns(6);
			height: 100%;
		}
		.article-right {
			@include span-columns(6);
			@include omega();
		}
		a {
			margin-bottom: 0;
		}
	}
	.featured-articles .article-bck {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
	}
}

@include MQ(L) {
	.featured-articles .article-left {
		figure {
			position: absolute;
			left: 30px;
			top: 25px;
		}
		.article--content {
			max-width: 100%;
		}
		.article--text {
			margin-left: 95px;
		}
		h2 {
			padding: 0 0 20px 25px;
			margin: 15px 0 25px 0;
			border-bottom: 1px solid #b9b9b9;
		}
		h3 {
			padding-left: 25px;
			margin-bottom: 15px;
		}
		p {
			padding-left: 25px;
			width: 80%
		}
		.tags {
			padding-left: 25px;
		}
		.article--action {
			padding-left: 25px;
		}
	}
}


/* -- PROMOTION SECTION -- */

.promotion {
	background-color: $light-blu;
	color: $white;
	figure {
		margin: 0 auto;
		width: 115px;
		height: auto;
	}
	.article {
		h2 {
			text-transform: uppercase;
			margin-bottom: 25px;
		}
		p {

		}
	}
	.article-left {
		.article--action {
			margin-top: 15px;
		}
		a {
			float: right;
		}
	}
	.article-right {
		border-top: 2px solid $white;
		form {
			position: relative;
			margin: 15px 0 10px 0;
		}
		input[type="email"] {
			display: block;
			width: 100%;
			@include border-radius(8px);
			background: rgba(255,255,255,.8);
			border: none;
			outline: none;
			height: 35px;
			padding: 5px 15px;
		}
		input[type="email"]::-webkit-input-placeholder{
	   		visibility: hidden;
		}
		input[type="email"]::-moz-input-placeholder{
	   		visibility: hidden;
		}
		input[type="email"]::-ms-input-placeholder{
	   		visibility: hidden;
		}

		input[type="submit"] {
			height: 35px;
			position: absolute;
			right: -10px;
			top: 0;
		}
		.note {
			display: block;
			color: $blu;
			@include font-size(1.2);
		}
	}
	.article--content {
		padding: 40px 30px;
	}
}

@include MQ(M) {
	.promotion {
		.article-left figure {
			position: absolute;
			left: 30px;
			top: 25px;
			width: 95px;
		}
		.article-left .article--text {
			margin-left: 105px;
		}
		.article h2 {
			@include font-size(1.8);
		}
		.article p {
			@include font-size(1.3);
			@include line-height(1.6);
		}
		.article-right {
			border-top: none;
		}
		.article-right input[type="email"]::-webkit-input-placeholder{
	   		@include font-size(1.2);
	   		visibility: visible;
		}
		.article-right input[type="email"]::-moz-input-placeholder{
	   		visibility: visible;
	   		@include font-size(1.2);
		}
		.article-right input[type="email"]::-ms-input-placeholder{
	   		visibility: visible;
	   		@include font-size(1.2);
		}
	}
}
@include MQ(M) {
	.promotion {
		.article-left figure {
			width: 115px;
		}
		.article-left .article--text {
			margin-left: 135px;
		}
		.article h2 {
			@include font-size(2.4);
		}
		.article p {
			@include font-size(1.6);
			@include line-height(1.9);
		}

		.article-right input[type="email"]::-webkit-input-placeholder{
	   		@include font-size(1.4);
		}
		.article-right input[type="email"]::-moz-input-placeholder{
	   		@include font-size(1.4);
		}
		.article-right input[type="email"]::-ms-input-placeholder{
	   		@include font-size(1.4);
		}
	}
}
.article-content iframe {
	width: 100%;
	padding-bottom: 100%;
}

/* -- SQUARED FEATURES SECTION -- */

.squared-features {
	.article-bck {
		padding-bottom: 100%;
		.article--content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url('../img/banner02.jpg') no-repeat 0 0;
			background-size: cover;
		}
		h2 {
			@include font-size(2.4);
			@include line-height(3.2);
			color: $red;
		}
		@include MQ(M) {
			padding-bottom: 50%;
			h2 {
				@include font-size(3);
				@include line-height(4);
			}
		}

	}

}

.fb-page,
	.fb-page span,
.fb-page span iframe[style] {
    width: 100% !important;
}
div._2p3a{
	width: 100%;
}

/* -- FEATURED PRODUCTS SECTION -- */

.article-product {
	background-color: $grey;
	border: 10px solid $grey;
	outline: 0;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
	padding-bottom: 60%;
	position: relative;
	margin-bottom: 15px;
	figure {
		width: 50%;
		margin: 0 auto;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	h3 {
		margin-bottom: 5px;
	}

	.article--content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.article--text {
		position: absolute;
		bottom: 30px;
		width: 100%;
		left: 0;
	}
}

@include MQ(M) {
	.article-product {
		@include span-columns(4);
		@include omega(3n);
		padding-bottom: calc(100% / 3);
		margin-bottom: 0;
		figure {
			width: 100%;
			margin-top: 20px;
		}
	}
}

@include MQ(L) {
	.article-product {
		img {
			opacity: 0.95;
			-webkit-transition: -webkit-transform 0.35s;
			transition: transform 0.35s;
			-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
		}
		h3 {
			-webkit-transition: -webkit-transform 0.35s;
			transition: transform 0.35s;
			-webkit-transform: translate3d(0,20px,0);
			transform: translate3d(0,20px,0);
		}
		p {
			opacity: 0;
			-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
			transition: opacity 0.35s, transform 0.35s;
			-webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg);
			transform: perspective(1000px) rotate3d(1,0,0,90deg);
			-webkit-transform-origin: 50% 0%;
			transform-origin: 50% 0%;
			}
		&:hover {
			border: 10px solid darken($grey, 5%);
			h3 {
				-webkit-transform: translate3d(0,0,0);
				transform: translate3d(0,0,0);
			}
			p {
				opacity: 1;
				-webkit-transform: perspective(1000px) rotate3d(1,0,0,0);
				transform: perspective(1000px) rotate3d(1,0,0,0);
			}
			img {
					-webkit-transform: scale3d(0.95,0.95,1);
					transform: scale3d(0.95,0.95,1);
				}
		}

	}
}


/* -- BRANDS CATEGORY SELECTION SECTION -- */

.brands {
	margin-bottom: 0;
	padding: 20px 0;
	background-color: $grey;
	text-align: center;
	li {
		display: inline-block;
		width: 100px;
		height: auto;
	}
	h2 {
		margin-bottom: 15px;
	}

	@include MQ(M) {
		.outer-container {
	 	  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		   align-items: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		}
		li {
			width: 130px;
		}


		h2, ul, li {
			display: inline-block;
			margin: 0 10px;
		}
	}
}

/* -- FOOTER -- */

footer {
	background-color: $blu;
	color: $white;
	.left, .right {
		padding: 20px;
		overflow: hidden;
	}
	h2 {
		text-transform: uppercase;
		@include font-size(1.2);
		font-weight: 500;
		margin-bottom: 15px;
	}
	.vcard {
		@include font-size(1.2);
		font-weight: 300;
		letter-spacing: .5px;
		float: left;
		width: 50%;
		margin-bottom: 15px;
		span {
			@include line-height(1.9);
		}
	}
	.footer-logo {
		display: inline-block;
		width: 80px;
		height: auto;
		margin-right: 10px;
	}
	.cd-social {
		padding: 0;
		margin: 0;
		border: none;
		display: inline-block;
		li {
			display: inline-block;
		}
		a {
			color: $white;
		}
	}
	@include MQ(M) {
		h2 {
			display: inline-block;
			float: left;
			width: calc( 100% / 3);
		}
		.vcard {
			width: calc( 100% / 3);
		}
		.right {
			text-align: right;
		}
		.footer-logo {
			width: 120px;
		}
	}
	@include MQ(L) {
		.left {
			float: left;
			padding: 40px 0 0 20px;
			width: 60%;
		}
		.right {
			float: right;
			width: 40%;
			padding: 40px 20px 0 0;
		}
		h2 {
			display: inline-block;
			float: left;
			width: 30%;
		}
		.vcard {
			width: 30%;
		}
		.right {
			text-align: right;
		}
	}
}

.hoverslide {
	position: fixed;
	top: 200px;
	z-index: 10;
	overflow: hidden;
	display: none;
	@include MQ(L) {
		top: 280px;
	}
}
.hoverslide-left {
	left: -100px;
}
.hoverslide a {
	display: block;
}
.hoverslide-icon, .hoverslide-text {
	display: block;
	float: left;
	height: 40px;
	color: $white;
	width: 100px;
}
.hoverslide-icon {
	width: 66px;
	border-left: 1px solid $white;
	text-align: center;
}
.hoverslide-text {
	padding: 10px;
	text-transform: uppercase;
	@include font-size(1.2);
	font-weight: 300;
	background-color: $red;
	text-align: center;
}


/* -- BREADCRUMBS -- */
.breadcrumbs {
	margin: 20px 10px;
	padding-bottom: 5px;
	border-bottom: 1px solid #c6c6c6;
	li {
		display: inline-block;
		@include font-size(1.4);
		padding-left: 5px;
	}
	li:before {
		content: '/';
		display: inline-block;
		padding-right: 5px;
	}
	li:first-child:before {
		content: '';
		padding-right: 0;
	}
	a {
		@include font-size(1.4);
		font-weight: 300;
	}
}



/* -- SCHEDA PRODOTTO -- */
.dropdown {
	margin: 0 10px 10px 10px;

  	$dropdown-distance-from-menu: 60px;
  		@include MQ(M) {
  			height: 100%;
  			margin-left: 0;
  			@include span-columns(3);
  			margin-right: 10px;
  		}

  .dropdown-container {
    position: relative;
  }

  .dropdown-description {
    float: left;
    padding-right: 1em;
  }

  .dropdown-button {
    cursor: pointer;
    position: relative;
    border: 1px solid $red;
    background-color: $red;
    color: $white;
	padding: 20px 30px;
	display: block;
	width: 100%;
  	line-height: 1.2em;
  	height: 60px;
  	i {
  		position: relative;
  		top: -3px;
  	}
  	@include MQ(M) {
  		float: none;
    	i {
    		display: none;
    	}
    }

  }

  .dropdown-button:after {
    display: block;
    position: absolute;
  }

  .dropdown-menu {
    cursor: pointer;
    position: absolute;
    text-align: left;
    display: none;
    top: $dropdown-distance-from-menu;
    width: 100%;
    z-index: 99999;
    background-color: $grey;

    @include MQ(M) {
    	display: block !important;
    	position: static;
    	height: 100%;
    }

    a {
    	font-family: $primary-font;
    	font-weight: 300;
    	padding: 8px 30px;
    	display: block;
    	line-height: 1.2em;
    	border-bottom: 1px solid rgba(0,0,0, .1);
    	position: relative;
    	@include font-size(1.4);
    }
    a.active:before {
    	font-family:'FontAwesome';
	    content: "\f105";
	    display: block;
	    position: absolute;
	    left: 18px;
	    top: 0;
	    line-height: 35px;
    }
  }

}

.product {
	margin: 0 10px;
	 @include MQ(M) {
    	@include span-columns(9);
    	margin: 0;
    	
    }
}
.product_title-container {
	cursor: pointer;
    position: relative;
    border: 1px solid $blu;
    background-color: $blu;
    color: $white;
	padding: 12px 30px;
	display: block;
	width: 100%;
  	font-weight: 300;
  	@include font-size(3);
  	line-height: 1.2em;
  	height: 60px;
}
.product-card {
	padding: 12px 30px;
}
.product-code {
	@include font-size(2.4);
	color: $blu;
	font-weight: 300;
}
.product-description {
	@include font-size(1.4);
	line-height: 16px;
	font-weight: 300;
}
.product-code--container, .product-brand--container, .product-image--container, .made-in-italy--container, .product-description--container, .product-options--container {
	margin-bottom: 20px;
	
	span {
		@include font-size(2.1);
		color: $blu;
		font-weight: 300;
		padding: 0 15px;
		border-right: 1px solid $text;
		&:last-child {
			border-right: 0;
		}
		&:first-child {
			padding-left: 0;
		}
	}
}

.product-related-articles {
	background-color: $grey;
}



/*  PAGINA CHI SIAMO TIMELINE STYLE */
.page-title {
	padding: 0 10px;
	margin: 10px 0;
}
.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}
.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
  overflow: hidden;
}
.cd-horizontal-timeline .timeline {
  position: relative;
  height: 640px;
  width: 100px;
  float: left;
  margin: 40px 0;
}
.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 20px;
  width: 100%;
}
.cd-horizontal-timeline .events-wrapper::before {
  left: 50%;
    -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);

  background-image: -webkit-linear-gradient( top , #ffffff, rgba(248, 248, 248, 1));
  background-image: linear-gradient(to bottom, #ffffff, rgba(248, 248, 248, 0));
}
.cd-horizontal-timeline .events-wrapper::after {
  bottom: 0;
  top: auto;
  background-image: -webkit-linear-gradient( bottom , #ffffff, rgba(248, 248, 248, 1));
  background-image: linear-gradient(to top, #ffffff, rgba(248, 248, 248, 0));
}
.cd-horizontal-timeline .events {
  width: 2px;
  position: absolute;
  z-index: 1;
  left: 50%;
  height: 100%;
   -webkit-transform: translateX(-52%);
  -moz-transform: translateX(-52%);
  -ms-transform: translateX(-52%);
  -o-transform: translateX(-52%);
  transform: translateX(-52%);
  top: 0;  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.cd-horizontal-timeline .events ol li {
  display: block;
}
.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #12416f;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -ms-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
  position: absolute;
  z-index: 2;
  width: 100%;
  min-width: 70px;
  text-align: center;
  font-size: 1.3rem;
  color: #444;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-horizontal-timeline .events a::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #12416f;
  -webkit-transition: background-color 0.3s, border-color 0.3s, scale 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s scale 0.3s;
  transition: background-color 0.3s, border-color 0.3s scale 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #12416f;
  border-color: #12416f;
}
.cd-horizontal-timeline .events a.selected {
  pointer-events: none;

}
.cd-horizontal-timeline .events a.selected::after {
  background-color: #ffffff;
  border: 2px solid #12416f;
  transform: scale(1.7);
}
.cd-horizontal-timeline .events a.older-event::after {
  border-color: #12416f;
}
@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline {
    margin: 6em auto;
  }
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}


.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  bottom: auto;
 
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
  /* arrow icon */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  background: url(../img/cd-arrow.svg) no-repeat 0 0;
  background-size: cover;
}
.cd-timeline-navigation a.prev {
  top: -40px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(180deg);
  -moz-transform: translateX(-50%) rotate(180deg);
  -ms-transform: translateX(-50%) rotate(180deg);
  -o-transform: translateX(-50%) rotate(180deg);
  transform: translateX(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
  bottom: -40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.no-touch .cd-timeline-navigation a:hover {
  border-color: #12416f;
}
/*.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}*/
/*.cd-timeline-navigation a.inactive::after {
  background-position: 0 100%;
}*/
/*.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}*/

.cd-horizontal-timeline .events-content {
  position: relative;
  width: calc(100% - 100px);
  height: 720px;
  padding: 0 2em;
  float: right;
  margin: 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
  position: absolute;
  z-index: 1;
   margin: 0 2em;
  height: 720px;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0 5%;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}

.cd-horizontal-timeline .events-content h2 {
  font-size: 2.6rem;
  font-family: $primary-font;
  font-weight: 700;
  @include font-size(2.6);
  @include line-height (3.2);
  margin-bottom: 13px;
}

.cd-horizontal-timeline .events-content p {
   @include font-size(1.4);
   color: $text;
   font-weight: 300;
   font-family: $primary-font;
}
.cd-horizontal-timeline .events-content img {
	display: block;
	width: auto;
	margin: 15px auto 0 auto;
	max-height: 500px;
   }
.cd-horizontal-timeline .events-content em, .cd-horizontal-timeline .events-content p {
   @include line-height (1.9);
}
@media only screen and (min-width: 768px) {
  .cd-horizontal-timeline .events-content h2 {
    @include font-size(3.2);
    @include line-height (3.6);
    margin-bottom: 16px;
  }

  .cd-horizontal-timeline .events-content p {
    @include font-size(1.8);
    @include line-height (2.4);
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}



